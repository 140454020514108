import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-toast-notification></app-toast-notification>
  `,
})
export class AppComponent {
  title = 'portal-solution';
}
