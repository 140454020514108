import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Observable, catchError, throwError, pipe, tap, BehaviorSubject, map } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { IUser } from '../core/interfaces/iLogin';
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private api = environment.url;

  private emailRegisteredStorage = sessionStorage.getItem('emailRegistered')
  private sharedSubjectEmailRegistered = new BehaviorSubject(this.emailRegisteredStorage ? this.emailRegisteredStorage : '');
  
  sharedEmailRegistered$ = this.sharedSubjectEmailRegistered.asObservable()

  constructor(private http: HttpClient, private dashboardService: DashboardService) { }

  handleCreateAccount(data: IUser): Observable<any> { 
    return this.http.post<any>(`${this.api}/usuarios/cadastro`, data)
  }

  handleSignIn(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.api}/login`, {
      email: email,
      password: password
    }).pipe(tap((res) => {

        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
    
        let tokenMain = res['bearer-jwt-gc'];
        let refreshToken = res['refresh_token'];
        let tokenBi = res['bi-manager'];
    
        let biStorage = tokenBi;
    
        if(!tokenMain) {
          console.log("NAO ACHEI O TOKEN PRINCIPAL DO BI")
        } 
    
        this.dashboardService.setNewToken(tokenMain);
        this.dashboardService.setNewRefreshToken(refreshToken);
        localStorage.setItem("bi_storage", JSON.stringify(biStorage));

        const currentRole = this.dashboardService.getUserRole();

        this.dashboardService.setRole(currentRole);
    }))
  }

  handleRecoveryPassword(email: string): Observable<any> {
    return this.http.get<any>(`${this.api}/usuarios/redefinir-senha/enviar-email/${email}`)
  }

  handleUpdatePassword(token: string, password: string, newPasswordConfirm: string) {
    return this.http.put<any>(`${this.api}/usuarios/redefinir-senha`, {
      new_password : password,
      new_password_confirmation: newPasswordConfirm
    }, 
    {
      headers: {
        'Authorization': "Bearer "+ token
      }
    })
  }

  handleActiveAccount(hash: string): Observable<any> {
    return this.http.get<any>(`${this.api}/usuarios/ativacao/${hash}`)
  }

  handleGenRandomPassword(): Observable<any> { 
    return this.http.get<any>(`${this.api}/gestao-conta/gerar-senha`).pipe(map(res => {
      return res.message.split(":")[1].trim();
    }))
  }

  handleCheckEmailRegistedUser(email: string) { 
    this.sharedSubjectEmailRegistered.next(email);
    sessionStorage.setItem('emailRegistered', email)
  }

  getNewTokenByRefreshToken(): Observable<any> { 

    const accessToken = this.dashboardService.getToken();
    const refreshToken = this.dashboardService.getRefreshToken();

    if(!accessToken || !refreshToken) { 
      throw new Error("Tokens de autenticação não encontrado!")
    }

    return this.http.put<any>(`${this.api}/login/refresh`, {}, {
      headers: {
        "Authorization": accessToken,
        "Refresh": refreshToken,
      }
    })
  }

}
