import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastType } from '../interfaces/IToast';

export interface IToastHeader {
  type: ToastType,
  title: string,
  message: string,
  icon: "check" | "baloon" | "star" | "warning"
}

@Injectable({
  providedIn: 'root',
})
export class HeaderToastService {
  private dashboardContentEl: HTMLElement | null = null;
  private toastsCreated: IToastHeader[] = [];
  public toasts$ = new BehaviorSubject<IToastHeader[]>([]);

  constructor() { }

  getDashboardContentEl(el: HTMLElement) {
    this.dashboardContentEl = el;
  }

  private toUpScrollDashboardContent() {

    if(this.dashboardContentEl) { 
      /* Toda vez que um toast for criado, o scroll do usuário vai ser redirecionado para o topo da página */
      this.dashboardContentEl.style.scrollBehavior = 'smooth';
      this.dashboardContentEl.scrollTop = 0
    }
   
  }

  handleCreateToast(type: ToastType, title: string, message: string) { 

    let icon: "check" | "baloon" | "star" | "warning" = "check"

    if(type == "success") icon = "check";
    if(type == 'warning') icon = "star";
    if(type == 'infor') icon = "baloon";
    if(type == 'error') icon = "warning";

    let toastCreated: IToastHeader = {
      icon: icon,
      message: message,
      title: title,
      type: type
    }

    this.toastsCreated.push(toastCreated)
    this.toasts$.next(this.toastsCreated)

    this.toUpScrollDashboardContent();
  }

  handleClearToasts() { 
    this.toastsCreated = [];
    this.toasts$.next(this.toastsCreated)
  }

  handleDeleteToast(index:number) { 
    this.toastsCreated.splice(index, 1)
  }

}
