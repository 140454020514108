import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthBiInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const currentUrl = request.url;

    const urlAllowedAuthorization = [
      'powerbi/filtros',
      'usuarios/relatorio/embed',
      'usuarios/menus'
    ]

    const validatePermission = urlAllowedAuthorization.some((url) => request.url.includes(url))

    if(validatePermission) { 
      return next.handle(request);
    } else {
      return next.handle(request);
    }

  }
}
