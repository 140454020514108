import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./login/login.routes").then(x => x.LOGIN_ROUTES)
  },
  {
    path: "invite/b2b/:idInvite",
    loadComponent: () => import("./invite/b2b/b2b.component").then(x => x.B2bComponent)
  },
  {
    path: "invite/patient/link-patient/:hash",
    loadComponent: () => import("./invite/patient/patient.component").then(x => x.PatientComponent)
  },
  {
    path: "invite/success",
    loadComponent: () => import("./invite/success/success.component").then(x => x.SuccessComponent)
  },
  {
    path: "dashboard",
    loadChildren: () => import("./dashboard/dashboard.routes").then(x => x.DASHBOARD_ROUTES),
    canActivate: [authGuard]
  },
  {
    path: "**",
    redirectTo: "",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
