import {
  animate,
  group,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const ToastNotificationAnimation = trigger(
  'ToastNotificationAnimation',
  [
    transition(
      ':enter',
      group([
        style({
          opacity: 0,
          left: '-100%',
        }),
        animate(
          300,
          style({
            opacity: 300,
            left: '15px',
          })
        ),
      ])
    ),
    transition(
      ':leave',
      group([
        style({
          opacity: 300,
          left: '15px',
        }),
        animate(
          300,
          style({
            opacity: 0,
            left: '-100%',
          })
        ),
      ])
    ),
  ]
);
