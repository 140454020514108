import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IToast {
  title: string;
  description: string;
  type: 'success' | 'error' | 'warning' | 'infor';
}

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {

  toastList: IToast[] = [];
  
  createToast({ title, description, type }: IToast) {
    let newToast: IToast = {
      description,
      title,
      type,
    };

    this.toastList.push(newToast);
  }

  deleteToast(toastSelectedIndex: number) {
    let newToastList = this.toastList.filter((toastItem, index) => {
      return index != toastSelectedIndex;
    });

    this.toastList = newToastList;
  }

  clearToasts() { 
    this.toastList = [];
  }
}
