import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { StoreModule } from '@ngrx/store';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { AuthInterceptor } from './core/interceptors/auth';
import { HandleErrorsInterceptor } from './core/interceptors/handle-errors.interceptor';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuthBiInterceptor } from './core/interceptors/auth-bi.interceptor';
import { ToastNotificationComponent } from './shared/components/toast-notification/toast-notification.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DatePipe } from '@angular/common';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [AppComponent, ToastNotificationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    Ng2ImgMaxModule,
    StoreModule.forRoot({}, {}),
    NgIdleKeepaliveModule.forRoot(),
    AngularSvgIconModule.forRoot()
  ],
  providers: [
    DatePipe,
    provideEnvironmentNgxMask(maskConfig),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthBiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
