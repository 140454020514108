import { EventEmitter, Injectable, Output } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, tap } from 'rxjs';
import { User } from '../core/interfaces/IUser';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IRole } from '../core/interfaces/IRole';
import { IModule } from '../core/interfaces/IModule';
import { HeaderToastService } from '../core/services/header-toast.service';
import { ActivatedRoute } from '@angular/router';

export interface IParam {
  [key: string]: string
}

export const userDefault: User = {
  _id: '',
  name: '',
  cpf: '',
  lastname: '',
  email: '',
  role: 'USER',
  address: {
    zip_code: '',
    neighborhood: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    street: '',
  },
  hash: '',
  activate: 1,
  responsible_id: '',
  customers: [],
  user_bi_id: 0,
  phone: '',
  company_name: {
    cnpj_org: '',
    nome_org: '',
  },
  cnpj: '',
  profile: [],
  group: null,
  sms_notification: false,
  email_notification: false,
  responsible: null,
  modules: [],
  filters: [],
  responsibles: [],
  logo: null,
  userPhoto: '',
};

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private api = environment.url;

  private userDataSubject = new BehaviorSubject<{
    isLoading: boolean;
    hasError: boolean;
    user: User;
    error: any;
  }>({
    isLoading: true,
    hasError: false,
    user: userDefault,
    error: null,
  });

  userData$ = this.userDataSubject.asObservable();

  private roleSubject = new BehaviorSubject<IRole>(this.getUserRole());
  role$ = this.roleSubject.asObservable();

  private paramsSubject = new BehaviorSubject<IParam>({});
  params$ = this.paramsSubject.asObservable();

  private isMenuOpenedStorage = localStorage.getItem('isMenuOpened');
  private _opened: boolean = this.isMenuOpenedStorage
    ? JSON.parse(this.isMenuOpenedStorage)
    : false;
  @Output() onAsideObs = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private headerToastService: HeaderToastService,
  ) {}

  onChangeOpened() {
    this._opened = !this._opened;
    this.onAsideObs.emit(this._opened);

    localStorage.setItem('isMenuOpened', JSON.stringify(this._opened));
  }

  handleDecodeJwt(): any | null {
    let token = this.getToken();

    if (token) {
      try {
        let tokenDecode = jwtDecode(token);

        return tokenDecode;
      } catch (err) {
        return new Error('Token não especificado!');
      }
    }

    return null;
  }

  getUserRole(): IRole {
    const decoded = this.handleDecodeJwt();

    if(!decoded) return "PACIENTE";

      const role = decoded.role as IRole;

    return role;
  }

  getToken() {
    let token = localStorage.getItem('access_token');

    return token;
  }

  setNewToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  getRefreshToken() {
    let token = localStorage.getItem('refresh_token');

    if (token) {
      return token;
    }

    return null;
  }

  setNewRefreshToken(refreshToken: string) {
    localStorage.setItem('refresh_token', refreshToken);
  }

  fetchUserData() {
    const decoded = this.handleDecodeJwt();

    return this.http.get<User>(`${this.api}/usuarios/buscar-id/${decoded.user_id}`)
      .pipe(
      tap({
        next: (user) => {
          let data = {
            ...user,
            modules: this.handleModulesAccessUser(user.modules),
          };

          this.setDataInUser(data, false, false, null);
        },
        error: (err) => {
          const { message } = err as any;

          this.headerToastService.handleCreateToast(
            'error',
            'Informações do usuário',
            message.message
          );
          
          this.setDataInUser(userDefault, false, true, err);
        },
      })
    );
  }

  setDataInUser(user: User, isLoading = true, hasError = false, error: any) {
    this.userDataSubject.next({
      user: user,
      isLoading,
      hasError,
      error,
    });
  }

  handleModulesAccessUser(
    userModules: IModule[]): IModule[] {
    let updatedModules = [...userModules];
    let role = this.getUserRole();

    const moduleResponderQuestionario_DEV = {
      id: '1c2a630f-c02a-4c1a-a9f8-b6d380bf43a3',
      description: 'Responder questionários',
    };

    const moduleResponderQuestionario_PRD = {
      id: '22c13444-f5fe-4c94-8812-d0d0b72d991f',
      description: 'Responder questionários',
    };

    updatedModules = updatedModules
      .map((module: any) => {
        const styledModule = {
          ...module,
          ...this.handleStyleAsideItem(module.description),
        };

        return styledModule;
      })
      .filter((module: IModule) => {
        /* Para nao aparecer o responder questionário na visão admin */
        if (role != 'PACIENTE') {
          return module.id != moduleResponderQuestionario_DEV.id && module.id != moduleResponderQuestionario_PRD.id && module.description != "Responder questionários";
        } else {
          return module;
        }
      });

    const reorderedModules = this.reorderItems(updatedModules);

    return reorderedModules;
  }

  reorderItems(items: IModule[]) {
    const myAccountIndex = items.findIndex(
      (item) => item.description === 'Minha conta'
    );
    if (myAccountIndex !== -1) {
      const minhaContaItem = items.splice(myAccountIndex, 1)[0];
      items.unshift(minhaContaItem);
    }
    return items;
  }

  handleStyleAsideItem(moduleName: string): IModule {
    if (moduleName.includes('Minha conta')) {
      return {
        description: moduleName,
        path: '/dashboard/account',
        isReportMenu: false,
        icon: 'assets/icon/aside-account-ico.svg',
        paragraph:
          'Precisa atualizar os seus dados de acesso? É só clicar aqui',
        pathImage: 'assets/onboarding/ThERA_HealthSuite_Card1024px_Conta.jpg',
        isOpened: false,
        subItems: [
          {
            title: 'Minha conta',
            isSelected: false,
            path: '/dashboard/account',
            roles: ['ADMIN', 'RH', 'MEDICO', 'PACIENTE']
          },
          {
            title: 'Gerenciar usuários',
            isSelected: false,
            path: '/dashboard/account/manage-users',
            roles: ['ADMIN']
          },
        ],
      };
    }

    if (moduleName.includes('Ask')) {
      return {
        description: moduleName,
        path: '/dashboard/ask/admin/questionnares',
        icon: 'assets/icon/aside-questionary-ico.svg',
        isReportMenu: false,
        subItems: [],
        isOpened: false,
        paragraph:
          'Crie ou acesse um mapeamento completo sobre o perfil de saúde dos colaboradores, incluindo hábitos, peso, idade, identificação de crônicos e muito mais.',
        pathImage: 'assets/onboarding/ThERA_HealthSuite_Card1024px_Ask.jpg',
      };
    }

    if (moduleName.includes('Analytics')) {
      return {
        description: moduleName,
        path: '/dashboard/analytics',
        icon: 'assets/icon/aside-relatorios-ico.svg',
        isReportMenu: true,
        subItems: [],
        isOpened: false,
        pathImage:
          'assets/onboarding/ThERA_HealthSuite_Card1024px_Analytics.jpg',
        paragraph:
          'Dados e cuidados de ponta a ponta: confira,analise e solucione visualizando históricos, comportamentos e demais utilizações dos benefícios.',
      };
    }

    if (moduleName.includes('Responder questionários')) {
      return {
        description: moduleName,
        path: '/dashboard/ask/patient/questionnares',
        icon: 'assets/icon/icon-default.svg',
        isReportMenu: false,
        isOpened: false,
        subItems: [],
        pathImage: 'assets/img/card-default.svg',
        paragraph:
          ' Explore e gerencie todas as informações necessárias de forma abrangente.',
      };
    }

    return {
      description: "Não definido",
      icon: 'assets/icon/icon-default.svg',
      pathImage: 'assets/img/card-default.svg',
      isOpened: false,
      path: "",
      subItems: [],
      paragraph:
        'Explore e gerencie todas as informações necessárias de forma abrangente.',
    };
  }

  onChangeProfile(email: string, role: string) {
    return this.http.put(`${this.api}/usuarios/adicionais/${email}`, {
      role,
    });
  }

  getAddressByCep(cep: string) {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`);
  }

  reloadUserByLogout() {
    this.userDataSubject.next({
      user: userDefault,
      isLoading: true,
      hasError: false,
      error: null,
    });
  }

  setRole(role: IRole) {
    this.roleSubject.next(role);
  }

  setParams(param: IParam) { 
    this.paramsSubject.next(param);
  }

  clearParams() { 
    
  }
}
