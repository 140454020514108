import { Component } from '@angular/core';
import { ToastNotificationService } from './toast-notification.service';
import { ToastNotificationAnimation } from './animation';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  animations: [ToastNotificationAnimation],
})
export class ToastNotificationComponent {
  constructor(public toastNotificationService: ToastNotificationService) {}

  handleCloseToast(toastSelectedIndex: number) {
    this.toastNotificationService.deleteToast(toastSelectedIndex);
  }
}
