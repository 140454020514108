<div class="toast-list">
  <div
    @ToastNotificationAnimation
    class="toast-container shadow-default"
    *ngFor="let toast of toastNotificationService.toastList; let i = index"
  >
    <img src="assets/icon/{{ toast.type }}-icon.svg" alt="Title" />
    <div class="toast-content">
      <p class="text-normal-bold">
        {{ toast.title }}
      </p>
      <p class="text-small-regular">
        {{ toast.description }}
      </p>
    </div>
    <button
      (click)="handleCloseToast(i)"
      type="button"
      class="toast-btn-close gray-7"
    >
      &times;
    </button>
  </div>
</div>
