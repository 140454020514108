import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})

export class authGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = localStorage.getItem('access_token');

    if (!token) {
      this.router.navigate(['']);
      return false;
    }

    if(token) {

      try { 
        const isValidToken = jwtDecode(token);
        return !!isValidToken;

      } catch(err) { 
        this.router.navigate(['']);
        return false;
      } 

    }

    return !!token;
  }
}
