import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { IErrorMessage } from '../interfaces/IErrorMessage';

@Injectable()
export class HandleErrorsInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {

      let messageError: IErrorMessage = {
        title: err.status + " - Parece que não tem ninguém aqui 🫠",
        message: err.error.message ? err.error.message : `Erro interno do servidor!`
      }

      let error = {
        ...err,
        message: messageError
      }

      if(err instanceof HttpErrorResponse) { 
        if(err.status === 403) { 

        messageError = {
          title: err.status + " - Erro de autorização",
          message: "Você não tem permissão para acessar este recurso. Por favor, entre em contato com o administrador do sistema."
        }

        error = {
          ...err,
          message: messageError
        }

          return throwError(error)
       }

       if(err.status === 500) { 

        messageError = {
          title: err.status + " - Parece que não tem ninguém aqui 🫠",
          message: `O servidor não encontrou o que você precisa. Se o erro persistir, fale com o nosso suporte.`
        }

        error = {
          ...err,
          message: messageError
        }
        
        return throwError(error)
       }

      }
      
      return throwError(error)
    }))
  }
}
