import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { LoginService } from "src/app/login/login.service";
import { HeaderToastService } from "../services/header-toast.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {



  constructor(private router: Router, private loginService: LoginService, private dashboardService: DashboardService, private headerToastService: HeaderToastService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token = this.dashboardService.getToken();

    const ignoredRoutes = [
      '/usuarios/relatorio/embed',
      '/login',
      '/usuarios/redefinir-senha/enviar-email/',
      '/usuarios/ativacao/',
      '/gerar-senha',
      '/usuarios/cadastro',
      '/usuarios/cadastrar-paciente'
    ]

    const isIgnored = ignoredRoutes.some(routeIgnored => req.url.includes(routeIgnored))

    if (isIgnored) {
      return next.handle(req);
    } else { 
      const authReq = req.clone({ headers: req.headers.set("Authorization", `Bearer ${token}`) });

      return next.handle(authReq).pipe(catchError(error => {

        if(error.status == 403) { 
          return this.refreshToken(req, next)
        }

        return throwError(error)
      }))
    }
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.loginService.getNewTokenByRefreshToken().pipe(
      switchMap((res) => {
        const newToken = res['bearer-jwt-gc'];
        const refreshToken = res['refresh_token'];

        this.dashboardService.setNewToken(newToken);
        this.dashboardService.setNewRefreshToken(refreshToken);

        const authReq = req.clone({ headers: req.headers.set("Authorization", `Bearer ${newToken}`) });
        return next.handle(authReq);
      }),
      catchError((err) => { 
        this.headerToastService.handleCreateToast('error', err.status + " - " + "Erro", "Erro de autorização")
        return throwError(err);
      })
    );
  }
}



